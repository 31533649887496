import React from "react";

import { Grid, Box, Typography, Card } from "@material-ui/core";

import PropertyField from "../Components/PropertyField";
import PropertyFieldWithSelect from "./PropertyFieldWithSelect";
import PropertyFieldWithInput from "./PropertyFieldWithInput";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const ApplicationSection = () => {
  const classes = useStyles();
  return (
    <Card style={{ height: "100%" }}>
      <Grid container className={classes.container}>
        <Grid item lg={12} xs={12}>
          <Box
            ml={1}
            mr={1}
            mt={3}
            mb={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            borderColor="#ffc107"
            borderRadius={16}
            border={2}
            pt={1}
            pb={1}
          >
            <Typography variant="h4">Application</Typography>
          </Box>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={3} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Write Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        <PropertyFieldWithSelect
          description="Measurement & transmission intervals"
          name="setMeasurementInterval"
          selectDefault={1}
          selectOptions={[
            { key: 1, value: 1, description: "1 reading / 24 hours" },
            { key: 2, value: 4, description: "1 reading / 6 hours" },
            { key: 3, value: 24, description: "1 reading / 1 hour" },
            { key: 4, value: 96, description: "1 reading / 15 min" },
          ]}
        />

        {/* <PropertyFieldWithSelect
        description="Level estimation"
        name="setLevelEstimation"
        selectDefault={false}
        selectOptions={[
          { key: 1, value: true, description: "Enabled" },
          { key: 2, value: false, description: "Disabled" },
        ]}
      /> */}

        <PropertyFieldWithInput
          description="100% fill level"
          name="setUpperOffset"
          inputDefault={450}
          inputMaxValue={6000}
          inputMinValue={0}
        />

        <PropertyFieldWithInput
          description="0% fill level"
          name="setBottomOffset"
          inputDefault={50}
          inputMaxValue={6000}
          inputMinValue={0}
        />

        <PropertyFieldWithInput
          description="Device position"
          name="setDevicePosZ"
          inputDefault={500}
          inputMaxValue={6000}
          inputMinValue={0}
        />

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={5} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Read Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        <PropertyField
          description="Measurement & transmission intervals"
          name="getMeasurementInterval"
        />

        <PropertyField description="100% fill level" name="getUpperOffset" />

        <PropertyField description="0% fill level" name="getBottomOffset" />

        <PropertyField description="Device position" name="getDevicePosZ" />
      </Grid>
    </Card>
  );
};

export default ApplicationSection;
