import React from "react";
import { Grid, Box, Typography, Card } from "@material-ui/core";
import PropertyField from "../Components/PropertyField";
import PropertyFieldWithSelect from "./PropertyFieldWithSelect";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const NetworkSection = () => {
  const classes = useStyles();
  return (
    <Card style={{ height: "100%" }}>
      <Grid container className={classes.container}>
        <Grid item lg={12} xs={12}>
          <Box
            ml={1}
            mr={1}
            mt={3}
            mb={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            borderColor="#ffc107"
            borderRadius={16}
            border={2}
            pt={1}
            pb={1}
          >
            <Typography variant="h4">Network</Typography>
          </Box>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={3} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Write Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        <PropertyFieldWithSelect
          description="Adaptive Data Rate"
          name="setAdaptiveDataRate"
          selectDefault={0}
          selectOptions={[
            { key: 1, value: 1, description: "Enabled" },
            { key: 2, value: 0, description: "Disabled" },
          ]}
        />

        <PropertyFieldWithSelect
          description="Spreading Factor"
          name="setSpreadingFactor"
          selectDefault={7}
          selectOptions={[
            { key: 1, value: 7, description: "7" },
            { key: 2, value: 8, description: "8" },
            { key: 3, value: 9, description: "9" },
            { key: 4, value: 10, description: "10" },
            { key: 5, value: 11, description: "11" },
            { key: 6, value: 12, description: "12" },
          ]}
        />

        <PropertyFieldWithSelect
          description="Uplink Confirmation"
          name="setUplinkConfirmation"
          selectDefault={0}
          selectOptions={[
            { key: 1, value: 1, description: "Enabled" },
            { key: 2, value: 0, description: "Disabled" },
          ]}
        />

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={5} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Read Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        {/* <PropertyField 
          description="Channel Mask"
          name="getChannelMask"
        /> */}

        <PropertyField
          description="Adaptive Data Rate"
          name="getAdaptiveDataRate"
        />

        <PropertyField
          description="Uplink Confirmation"
          name="getUplinkConfirmation"
        />
      </Grid>
    </Card>
  );
};

export default NetworkSection;
