import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import terabeeLogo from "../Assets/terabee.png";

const useStyles = makeStyles({
  logo: {
    maxWidth: 180,
  },
  appBar: {
    position: "static",
    background: "white",
  },
  headerText: {
    color: "black",
  },
});

const Header = () => {
  const classes = useStyles();

  const handlePageChange = () => {
    window.location.href =
      "https://www.terabee.com/shop/level-monitoring/terabee-lora-level-monitoring/";
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Box>
          <img
            src={terabeeLogo}
            alt="terabee"
            className={classes.logo}
            onClick={handlePageChange}
          />
        </Box>
        <Box ml={2}>
          <Typography variant="h6" className={classes.headerText}>
            Terabee downlink generator : LoRa Level Monitoring XL
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
