import React from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const GeneratedDownlinkField = ({ name, payload, index }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item xs={4} md={3}>
        <Typography>{`${index + 1}. ${name}`}</Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField
          variant="outlined"
          margin="dense"
          value={payload}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Copy">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(payload);
                    }}
                  >
                    <FileCopyIcon style={{ color: "#ffc107" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default GeneratedDownlinkField;
