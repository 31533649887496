import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";

import { ConfigurationContext } from "../Context/ConfigurationContext";

const PropertyFieldWithSelect = ({
  description,
  selectOptions,
  selectDefault,
  name,
}) => {
  const [configuration, setConfiguration] = useContext(ConfigurationContext);

  const [checked, setChecked] = useState(false);

  const handleAdd = (parameter, value) => {
    const newConfiguration = Object.assign({}, configuration);
    newConfiguration[parameter] = value;
    setConfiguration(newConfiguration);
  };

  const handleRemove = (parameter) => {
    const newConfiguration = { ...configuration };
    delete newConfiguration[parameter];
    setConfiguration(newConfiguration);
  };

  const handleEnable = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      handleAdd(name, selectDefault);
    } else {
      handleRemove(name);
    }
    setChecked(checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setConfiguration({
      ...configuration,
      [name]: value,
    });
  };

  const returnDefaultIfUndefined = (param, defaultValue) => {
    return param !== undefined ? param : defaultValue;
  };

  return (
    <Grid container alignItems="center" direction="row" spacing={2}>
      <Grid item lg={1} xs={1}>
        <Checkbox name={name} value={checked} onChange={handleEnable} />
      </Grid>
      <Grid item lg={6} xs={6}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Select
          variant="outlined"
          margin="dense"
          name={name}
          displayEmpty
          disabled={!checked}
          value={returnDefaultIfUndefined(configuration[name], selectDefault)}
          onChange={handleChange}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.description}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default PropertyFieldWithSelect;
