import React, { useState, useContext } from "react";
import { Grid, Typography, Checkbox } from "@material-ui/core";

import { ConfigurationContext } from "../Context/ConfigurationContext";

const PropertyField = ({ description, name }) => {
  const [configuration, setConfiguration] = useContext(ConfigurationContext);

  const [checked, setChecked] = useState(false);

  const handleAdd = (parameter) => {
    const newConfiguration = Object.assign({}, configuration);
    newConfiguration[parameter] = "";
    setConfiguration(newConfiguration);
  };

  const handleRemove = (parameter) => {
    const newConfiguration = { ...configuration };
    delete newConfiguration[parameter];
    setConfiguration(newConfiguration);
  };

  const handleEnable = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      handleAdd(name);
    } else {
      handleRemove(name);
    }
    setChecked(checked);
  };

  return (
    <Grid container alignItems="center" direction="row" spacing={2}>
      <Grid item lg={1} xs={1}>
        <Checkbox name={name} value={checked} onChange={handleEnable} />
      </Grid>
      <Grid item lg={6} xs={6}>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item lg={5} xs={5} />
    </Grid>
  );
};

export default PropertyField;
