import React from "react";
import PropertyField from "../Components/PropertyField";
import { makeStyles } from "@material-ui/core";

import { Grid, Box, Typography, Card } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const AboutSection = () => {
  const classes = useStyles();
  return (
    <Card style={{ height: "100%" }}>
      <Grid container className={classes.container}>
        <Grid item lg={12} xs={12}>
          <Box
            ml={1}
            mr={1}
            mt={3}
            mb={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            borderColor="#ffc107"
            borderRadius={16}
            border={2}
            pt={1}
            pb={1}
          >
            <Typography variant="h4">About Device</Typography>
          </Box>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={3} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Write Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        <PropertyField description="Device Restart" name="setDeviceRestart" />

        <Grid item lg={12} xs={12}>
          <Box ml={4} mt={3} mb={3}>
            <Typography>
              {" "}
              <b>
                <u>Read Parameters</u>
              </b>
            </Typography>
          </Box>
        </Grid>

        <PropertyField
          description="Device Serial Number"
          name="getDeviceSerialNumber"
        />

        <PropertyField
          description="Device Firmware Version"
          name="getDeviceFirmwareVersion"
        />
      </Grid>
    </Card>
  );
};

export default AboutSection;
