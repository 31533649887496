import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  CardMedia,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { ConfigurationContext } from "../Context/ConfigurationContext";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import silo from "../Assets/silo.png";

const ImageTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 1.0)",
  },
}))(Tooltip);

const PropertyFieldWithInput = ({
  description,
  inputMinValue,
  inputMaxValue,
  inputDefault,
  name,
}) => {
  const [configuration, setConfiguration] = useContext(ConfigurationContext);

  const [checked, setChecked] = useState(false);

  const handleAdd = (parameter, value) => {
    const newConfiguration = Object.assign({}, configuration);
    newConfiguration[parameter] = value;
    setConfiguration(newConfiguration);
  };

  const handleRemove = (parameter) => {
    const newConfiguration = { ...configuration };
    delete newConfiguration[parameter];
    setConfiguration(newConfiguration);
  };

  const handleEnable = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      handleAdd(name, inputDefault);
    } else {
      handleRemove(name);
    }
    setChecked(checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let newValue = value;
    if (value > inputMaxValue) {
      newValue = inputMaxValue;
    }

    if (value < inputMinValue) {
      newValue = inputMinValue;
    }

    setConfiguration({
      ...configuration,
      [name]: newValue === "" ? "" : Number(newValue),
    });
  };

  const handleBlur = (event) => {
    const { name } = event.target;

    if (configuration[name] === "") {
      setConfiguration({
        ...configuration,
        [name]: inputMinValue,
      });
    } else if (configuration[name] < inputMinValue) {
      setConfiguration({
        ...configuration,
        [name]: inputMinValue,
      });
    } else if (configuration[name] > inputMaxValue) {
      setConfiguration({
        ...configuration,
        [name]: inputMaxValue,
      });
    }
  };

  const returnDefaultIfUndefined = (param, defaultValue) => {
    return param !== undefined ? param : defaultValue;
  };

  return (
    <Grid container alignItems="center" direction="row" spacing={2}>
      <Grid item lg={1} xs={1}>
        <Checkbox name={name} value={checked} onChange={handleEnable} />
      </Grid>
      <Grid container alignItems="center" direction="row" item lg={6} xs={6}>
        <Grid item lg={4} xs={4}>
          <Typography>{description}</Typography>
        </Grid>
        <Grid item lg={1} xs={1}>
          <ImageTooltip
            title={
              <React.Fragment>
                <CardMedia>
                  <img src={silo} style={{ width: "500px" }} alt="Silo" />
                </CardMedia>
              </React.Fragment>
            }
          >
            <HelpOutlineIcon />
          </ImageTooltip>
        </Grid>
      </Grid>
      <Grid item lg={5} xs={5}>
        <OutlinedInput
          value={returnDefaultIfUndefined(configuration[name], inputDefault)}
          margin="dense"
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          disabled={!checked}
          inputProps={{
            step: 1,
            min: inputMinValue,
            max: inputMaxValue,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          endAdornment={<InputAdornment position="end">[cm]</InputAdornment>}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyFieldWithInput;
