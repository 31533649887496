import { crc8 } from "crc";

function calculateCrc(byteArray) {
  return crc8(Buffer.from(byteArray, "hex")).toString(16).padStart(2, "0");
}

const deviceRequests = {
  setSpreadingFactor: { header: "0315", description: "WRITE spreading factor" },
  getSpreadingFactor: { header: "0216", description: "READ spreading factor" },
  setBottomOffset: { header: "0423", description: "WRITE 0% fill level " },
  getBottomOffset: { header: "0224", description: "READ 0% fill level" },
  setUpperOffset: { header: "0421", description: "WRITE 100% fill level" },
  getUpperOffset: { header: "0222", description: "READ 100% fill level" },
  setDevicePosZ: { header: "0425", description: "WRITE device position" },
  getDevicePosZ: { header: "0226", description: "READ device position" },
  getMeasurementInterval: {
    header: "0219",
    description: "READ measurement interval",
  },
  setMeasurementInterval: {
    header: "0318",
    description: "WRITE measurement interval",
  },
  getDeviceSerialNumber: {
    header: "0203",
    description: "READ device serial number",
  },
  getDeviceFirmwareVersion: {
    header: "0204",
    description: "READ device firmware version",
  },
  setDeviceRestart: { header: "0234", description: "Power Reset" },
  setUplinkConfirmation: {
    header: "0432",
    description: "WRITE uplink confirmation",
  },
  getUplinkConfirmation: {
    header: "0233",
    description: "READ uplink confirmation",
  },
  setAdaptiveDataRate: {
    header: "0300",
    description: "WRITE adaptive data rate",
  }, // TODO WRONG HEADER CHECK WITH BONGANI
  getAdaptiveDataRate: {
    header: "0200",
    description: "READ adaptive data rate",
  }, // TODO WRONG HEADER CHECK WITH BONGANI
};

export const getDescription = (parameter) => {
  return deviceRequests[parameter].description;
};

const getPayloadLength = (parameter) => {
  const headerLength = 2;
  return (
    parseInt(deviceRequests[parameter].header.substring(0, 2)) - headerLength
  );
};

export const generatePayload = (parameter, value) => {
  let payload;
  const header = deviceRequests[parameter].header;

  payload = header;

  if (value !== "") {
    const payloadLength = getPayloadLength(parameter);
    payload = payload + value.toString(16).padStart(payloadLength * 2, "0");
  }

  payload = payload + calculateCrc(payload);
  return payload.toUpperCase();
};
