import DescriptionSection from "./Components/DescriptionSection";
import NetworkSection from "./Components/NetworkSection";
import AboutSection from "./Components/AboutSection";
import ApplicationSection from "./Components/ApplicationSection";
import GeneratedDownlinkSection from "./Components/GeneratedDownlinkSection";
import Header from "./Components/Header";
import { Grid } from "@material-ui/core";

import { ConfigurationProvider } from "./Context/ConfigurationContext";

function App() {
  return (
    <ConfigurationProvider>
      <div className="App">
        <header className="App-header">
          <Header />
        </header>
        <DescriptionSection />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item lg={4} md={12} xs={12}>
            <NetworkSection />
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <ApplicationSection />
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <AboutSection />
          </Grid>
        </Grid>
        <GeneratedDownlinkSection />
      </div>
    </ConfigurationProvider>
  );
}

export default App;
