import React, { useContext } from "react";
import { Card, Box, Typography } from "@material-ui/core";

import GeneratedDownlinkField from "./GeneratedDownlinkField";

import { ConfigurationContext } from "../Context/ConfigurationContext";

import { generatePayload, getDescription } from "./payloadGenerator";

const GeneratedDownlinkSection = () => {
  const [configuration] = useContext(ConfigurationContext);

  return (
    <Card>
      <Box
        ml={1}
        mr={1}
        mt={3}
        mb={3}
        alignItems="center"
        justifyContent="center"
        display="flex"
        borderColor="#ffc107"
        borderRadius={16}
        border={2}
        pt={1}
        pb={1}
      >
        <Typography variant="h4">Downlink payload generator</Typography>
      </Box>
      {Object.entries(configuration).map(([parameter, value], index) => (
        <GeneratedDownlinkField
          name={getDescription(parameter)}
          payload={generatePayload(parameter, value)}
          key={index}
          index={index}
        />
      ))}
    </Card>
  );
};

export default GeneratedDownlinkSection;
